const constants = {

    // Postup při zadávání druhého faktoru ověření uživatele
    sfNone: 0,      // Není potřeba
    sfSMS: 1,       // Klientský certifikát nebyl vybrát => ověření pomocí SMS
    sfNotMatch: 2,  // Vybrán jiný klientský certifikát, než který je registrován => dotaz
    sfNotActive: 3,  // Vybrán klientský certifikát, žádný ale není registrován => dotaz
    sfRegister: 4,  // Registrace nového certfikátu

    // Klávesy    
    keyBackspace: 8,
    keyTab: 9,
    keyEnter: 13,
    keyShift: 16,
    keyEsc: 27,
    keySpace: 32,
    keyPageUp: 33,
    keyPageDown: 34,
    keyEnd: 35,
    keyHome: 36,
    keyLeft: 37,
    keyUp: 38,
    keyRight: 39,
    keyDown: 40,
    keyDelete: 46,
    
    // Validační hlášky
    valRequired: 'Toto pole musí být vyplněno!',  
    valPINExists: 'Pacient s tímto rodným číslem již existuje!',  
    valCourseEventOrder: 'Chybná posloupnost průběhu léčby!',  
    valPersonalIdentificationNumber: 'Chybný formát rodného čísla!',
    valEmail: 'Chybný formát emailu!',  
    valPhoneNumber: 'Chybný formát telefonního čísla!',  
    valNoDuplicity: 'Každá položka smí být v seznamu pouze jednou!',  
    valInteger: 'Toto pole musí obsahovat celé číslo!',  
    valPositive: 'Toto pole musí obsahovat kladné číslo!',
    valNumber: 'Toto pole musí obsahovat číslo!',

    // Chybové hlášky
    errSave: 'Uložení změněných dat se nezdařilo.',

    // Skupiny uživatel
    ugAdministrators : 1,
    ugRDPCentre : 5,
    ugRIPCentre : 2,
    ugCentre : 4,
    ugStatTreat : 8,
    ugMIA : 9,
    ugRenalniBiopsie : 10,
    ugRDPPrehled : 11,
    ugRadaRDP : 12,
    ugParamOfTreatQuality : 13,
    ugPatientConsent: 14,    
    ugMBAuthor: 15,
    ugInsuranceCompany: 16,
    ugYearbookAuthor: 17,

    // Indexy tlačítek v horním menu
    tmbNew: 0,
    tmbEdit: 1,
    tmbDelete: 2,

    tmbReply: 3,
    tmbReplyAll: 4,
    tmbForward: 5,

    tmbAddPatient: 6,
    tmbChangeRegister: 7,        
    tmbFreePatient: 8,        
    tmbAddFreePatient: 9,        
    tmbDeletePatient: 10, 

    // První rok a kvartál vyplňování statistiky
    rdpFirstYearOfRDP: 2005,
    rdpFirstQuarterOfRDP: 4,

    // Jednotky
    unithod		:	" [hod] ",
    unithodEN	:	" [hour] ",
    unitIUtyden	:	" [IU/týden] ",
    unitIUtydenEN:	" [IU/week] ",
    unitpgml	:	" [pg/ml] ",
    unitgl		:	" [g/l] ",
    unitdgl		:	" [dg/l] ",
    unitmgl		:	" [mg/l] ",
    unitugl		:	" [&micro;g/l] ",
    unitmlmin	:	" [ml/min] ",
    unitmlminm2	:	" [ml/min/m<sup>2</sup>] ",
    unitm2		:	" [m<sup>2</sup>] ",
    unitml		:	" [ml] ",
    unitml24hod :   " [ml/24hod] ",
    unitl24hod  : " [l/24hod] ",
    unitmMoll24hod: " [mmol/24h] ",
    unitcm		:	" [cm] ",
    unitm		:	" [m] ",
    unitkg		:	" [kg] ",
    unitumoll	:	" [&micro;mol/l] ",
    unitmMoll	:	" [mmol/l] ",
    unitgd		:	" [g/d] ",
    unit1012l	:	" [10<sup>12</sup>/l] ",
    unitArbJ	:	" [arb.j.] ",
    unit1		:	" [1] ",
    unitmmHg	:	" [mmHg] ",
    unitPercent	:	" [%] ",
    unitkU		:	" [kU] ",
    unitstupen	:	" [stupeň] ",

    // Limity statistiky kvality léčby
    LimitValHb:  100,
    LimitValP:  1.8,
    LimitValKtV: 1.2,
    // Od 2022 se rozlišují různé ktv
    LimitValEKtV: 1.2,
    LimitValWKtV: 1.7,

    LimitHb: 70,
    LimitP: 50,
    LimitKtV: 70,
    TxWlPercentLimit: 0.07,

    // Konstanty z dbm
    dbmFemale: 'Female',
    dbmMale: 'Male',
    dbmRDP: 'RDP',
    dbmPOR: 'POR',

    dbmPer: 'Per',
    dbmReg: 'Reg',

    dbmStartPor       : 'StartPor',
    dbmStartRrtTpl    : 'StartRrtTpl',
    dbmStartRrtHD     : 'StartRrtHD',
    dbmStartRrtPD     : 'StartRrtPD',
    dbmMethodChangeHD : 'MethodChangeHD',
    dbmMethodChangePD : 'MethodChangePD',
    dbmMethodChangeTpl: 'MethodChangeTpl',
    dbmExitus         : 'Exitus',
    dbmRefusal        : 'Refusal',
    dbmFunctionReturn: 'FunctionReturn',
    dbmPalliativeTreatment: 'PalliativeTreatment',
    dbmHHDStart: 'HHDStart',
    dbmHHDEnd: 'HHDEnd',

    dbmIncluded   : 'Included',   
    dbmRemoveTemp : 'RemoveTemp',   
    dbmRemoveDoc  : 'RemoveDoc',   
    dbmRemoveDen  : 'RemoveDen',

    dbmCAPD_NAPD  : 'CAPD_NAPD',   
    dbmCAPD       : 'CAPD',
    dbmCCPD       : 'CCPD',    
    dbmHD         : 'HD',
    dbmHF_ON      : 'HF_ON',
    dbmHF_OFF     : 'HF_OFF',
    dbmHDF_ON     : 'HDF_ON',
    dbmHDF_ON_PRE : 'HDF_ON_PRE',
    dbmHDF_ON_POS : 'HDF_ON_POS',
    dbmHDF_OFF    : 'HDF_OFF',
    dbmHDF_OFF_PR : 'HDF_OFF_PR',
    dbmHDF_OFF_PO : 'HDF_OFF_PO',
    dbmAFB        : 'AFB',
    dbmPFD        : 'PFD',
    dbmPF         : 'PF',
    dbmHP         : 'HP',
    dbmHD_HP      : 'HD_HP',
    dbmHDF_MIXED  : 'HDF_MIXED',
    
    dbmVatPISTEL    : 'PISTEL',    
    dbmVatRC_AV     : 'RC_AV',   
    dbmVatBC_AV     : 'BC_AV',    
    dbmVatUC_AV     : 'UC_AV',    
    dbmVatUB_AV     : 'UB_AV',    
    dbmVatKANYLA    : 'KANYLA',    
    dbmVatPIST_KANIL: 'PIST_KANIL',    
    dbmVatKATETR    : 'KATETR',    
    dbmVatOTHER     : 'OTHER',

    dbmValLHK       : 'LHK',    
    dbmValPHK       : 'PHK',    
    dbmValDK        : 'DK',    
    dbmValV_JUGUL_P : 'V_JUGUL_P',   
    dbmValV_JUGUL_L : 'V_JUGUL_L',   
    dbmValV_SUBCL_P : 'V_SUBCL_P',   
    dbmValV_SUBCL_L : 'V_SUBCL_L',   
    dbmValV_FEMOR_P : 'V_FEMOR_P',   
    dbmValV_FEMOR_L : 'V_FEMOR_L',   
    dbmValOTHER     : 'OTHER',
    
    dbmInocFLU    : 'FLU',
    dbmInocTBC    : 'TBC',
    dbmInocTETANUS: 'TETANUS',
    dbmInocHEP_B  : 'HEP_B',
    dbmInocOTHER: 'OTHER',
    
    dbmDialTypeHF : 'HF',    
    dbmDialTypeLF: 'LF',

    dbmDialMemTypeREG_CEL    : 'REG_CEL',   
    dbmDialMemTypeMOD_CEL    : 'MOD_CEL',   
    dbmDialMemTypeREG_CEL_MO : 'REG_CEL_MO',   
    dbmDialMemTypeACETAT     : 'ACETAT',    
    dbmDialMemTypeDIACETAT   : 'DIACETAT',
    dbmDialMemTypeTRICETAT   : 'TRICETAT',
    dbmDialMemTypePOLYAKRYLO : 'POLYAKRYLO',
    dbmDialMemTypePOLYMETYLM : 'POLYMETYLM',
    dbmDialMemTypeKOPOLYMER  : 'KOPOLYMER',
    dbmDialMemTypePOLYKARBON : 'POLYKARBON',
    dbmDialMemTypePOLYAMID   : 'POLYAMID',
    dbmDialMemTypePOLYSULFON : 'POLYSULFON',
    dbmDialMemTypePOLYARYLET: 'POLYARYLET',
    
    dbmPdMethodCAPD_NAPD : 'CAPD_NAPD',
    dbmPdMethodCAPD      : 'CAPD',
    dbmPdMethodCCPD      : 'CCPD',

    dbmNegative  : 'Negative',   
    dbmPositive  : 'Positive',  
    dbmNoExamine: 'NoExamine',
    
    dbmPhosDrugRenagel   : 'Renagel',        
    dbmPhosDrugCalciumCA : 'CalciumCA',
    dbmPhosDrugCalciumAC : 'CalciumAC',
    dbmPhosDrugAluminium : 'Aluminium',
    dbmPhosDrugFosrenol  : 'Fosrenol',
    dbmPhosDrugZemplar   : 'Zemplar',
    dbmPhosDrugMimpara   : 'Mimpara',
    dbmPhosDrugVitaminD3 : 'VitaminD3',       
    
    dbmAntihypDrugBeta      : 'Beta',
    dbmAntihypDrugAce       : 'Ace',
    dbmAntihypDrugCaBlock   : 'CaBlock',
    dbmAntihypDrugCentral   : 'Central',
    dbmAntihypDrugARB       : 'ARB',
    dbmAntihypDrugDiuretika : 'Diuretika',
    dbmAntihypDrugAlfa      : 'Alfa',
    
    dbmDiabDrugPad     : 'Pad',
    dbmDiabDrugInsulin : 'Insulin',    

    dbmDyslipDrugStatiny : 'Statiny',
    dbmDyslipDrugFibraty: 'Fibraty',
    
    dbmErytrHemNeg      : 'Neg',
    dbmErytrHemMikro    : 'Mikro',
    dbmErytrHemMakro    : 'Makro',
    
    dbmHDLPgMl: 'PgMl',    
    dbmHDLPMolL: 'PMolL',
    
    dbmDiaTypeType1 : 'Type1',   
    dbmDiaTypeType2 : 'Type2',   
    dbmDiaTypeOther: 'Other',

    dbmNYHAI   : 'I',  
    dbmNYHAII  : 'II',   
    dbmNYHAIII : 'III',
    dbmNYHAIV: 'IV',
    
    dbmPhospBindCal         : 'Cal',
    dbmPhospBindNoncalc     : 'Noncalc',
    dbmPhospBindCalcNoncalc: 'CalcNoncalc',
    
    dbmMonContinue  : 'Continue',
    dbmMonDead      : 'Dead',
    dbmMonPDL       : 'PDL',
    dbmMonTX        : 'TX',
    dbmMonLost      : 'Lost',
    dbmMonFncReturn : 'FncReturn',

    dbmMonDeadCardSudde : 'CardSudde',
    dbmMonDeadCardOther : 'CardOther',
    dbmMonDeadOther: 'Other',

    dbmDrugTypeAntihypertensive: 'Antihypertensive',
    dbmDrugTypePhosphate: 'Phosphate',
    dbmDrugTypeDyslipidemia: 'Dyslipidemia',
    dbmDrugTypeDiabetes: 'Diabetes',

    dbmCentreTypePrivate    : 'Private',
    dbmCentreTypeState: 'State',

    dbmLimitsStandard       : 'Standard', 
    dbmLimitsUltraClean: 'UltraClean',

    dbmFreqDaily        : 'Daily',
    dbmFreqWeekly       : 'Weekly',
    dbmFreqMonthly      : 'Monthly',
    dbmFreqQuarterly    : 'Quarterly',
    dbmFreqHalfYear     : 'HalfYear',
    dbmFreqYearly: 'Yearly',

    dbmInfectionHIV: 'HIV',    
    dbmInfectionHCV: 'HCV',        
    dbmInfectionHBS: 'HBS',    

    dbmIsolationNoPatients  : 'NoPatients',
    dbmIsolationNone        : 'None'      ,
    dbmIsolationMonito      : 'Monito'    ,
    dbmIsolationBed         : 'Bed'       ,
    dbmIsolationShift       : 'Shift'     ,
    dbmIsolationRoom        : 'Room'      ,
    dbmIsolationPersonal    : 'Personal'  ,
                              
    dbmWaterModifOsmosis     : 'Osmosis'   ,
    dbmWaterModifFiltMech    : 'FiltMech'  ,
    dbmWaterModifFiltCoal    : 'FiltCoal'  ,
    dbmWaterModifDeionizat   : 'Deionizat' ,
    dbmWaterModifUVRay       : 'UVRay'     ,
    dbmWaterModifSoftening: 'Softening',

    dbmRDPDataTypeCentre: 'Centre',
    dbmRDPDataTypePatients: 'Patients',

    dbmConsentTypeDataProc: 1,
    dbmConsentTypePalliTrea: 2,
    
    dbmDeathCausesCardio       : 'Cardio',
    dbmDeathCausesInfection    : 'Infection',
    dbmDeathCausesMalignancies : 'Malignancies',
    dbmDeathCausesOther: 'Other',
    dbmDeathCausesUnknown: 'Unknown',

    dbmRRTStartFromTypesFromStreet: 'FromStreet',
    dbmRRTStartFromTypesFromOwnAmbulance: 'FromOwnAmbulance',
    dbmRRTStartFromTypesFromOtherAmbulance: 'FromOtherAmbulance',

    // Typy pacientů from filtr sumáře
    sumFilterPrevalent: "Prevalent",
    sumFilterIncidental: "Incidental",
    sumFilterExitus: "Exitus",
    sumFilterTransplanted: "Transplanted",
};

export default constants;

 
