import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withRouter } from 'utils/WithRouter.js';
import { withLoggedUserFormsContext } from './WithLoggedUserFormsContext.js';
import WSClient, { erOK } from '../WSClient.js';
import PopupMenu from './PopupMenu.js';

import { ReactComponent as AboutRDP } from '../images/aboutRDP.svg';
import { ReactComponent as Data } from '../images/data.svg';
import { ReactComponent as Statistic } from '../images/esports.svg';
import { ReactComponent as Mail } from '../images/mail.svg';
import { ReactComponent as Administration } from '../images/Administration.svg';

import Logo from 'images/logo.jpg';

import './MainMenu.css';

const treeData = [
    {
        text: 'Data pro pojišťovny',
        route: '/insComp/patients',
        visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserInsuranceCompany,
    },
    {
        text: 'Sumář',
        route: '/data/summary',
        visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre,
    },
    {
        text: 'Covid přehled 2021',
        route: '/data/summary2021',
        visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre,
    },
    {
        text: 'Data o středisku (vyplňují se 1x ročně za IV. kvartál)',
        route: '/data/centreData',
        visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre,
    }, 
    { text: 'Data o pacientech (vyplňují se 4x ročně po kvartálech)', route: '/data/patients', visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre },
    { text: 'Přehled reportování', route: '/data/centreSummary', visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRIPCentre },    
    {
        text: 'Import dat',
        route: '/data/import',
        visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre || WSClient.isCurrentUserRIPCentre,
    }];

const treeStat = [
    { text: 'Parametry kvality', route: '/stat/treatQualityParam', visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre || WSClient.isCurrentUserParamOfTreatQuality },
    { text: 'Aktuální národní data', route: '/stat/actualNationalDataReport', visible: () => WSClient.currentUser.clientCertificateState === erOK },
    { text: 'Porovnání střediska s národními daty', route: '/stat/centreReport', visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserRDPCentre || WSClient.isCurrentUserParamOfTreatQuality }];

const treeAdmin = [
    { text: 'Editor zpráv', route: '/messageBoard', visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserMBAuthor },
    { text: 'Editor statistické ročenky', route: '/yearbook', visible: () => WSClient.isCurrentUserAdministrator || WSClient.isCurrentUserYearbookAuthor },
    { text: 'Uživatelé', route: '/admin/users', visible: () => WSClient.isCurrentUserAdministrator },
    { text: 'Skupiny uživatel', route: '/admin/userGroups', visible: () => WSClient.isCurrentUserAdministrator },
    { text: 'Střediska', route: '/admin/centres', visible: () => WSClient.isCurrentUserAdministrator },
    { text: 'Zveřejnění aktuálních národních dat', route: '/admin/reportsExport', visible: () => WSClient.isCurrentUserAdministrator },    
    { text: 'Zveřejnění aktuálních adresných dat', route: '/admin/reportsDirectDataExport', visible: () => WSClient.isCurrentUserAdministrator },    
    { text: 'Nastroje administrátora', route: '/admin/utils', visible: () => WSClient.isCurrentUserAdministrator },
    { text: 'Statistiky administrátora', route: '/admin/stat', visible: () => WSClient.isCurrentUserAdministrator },        
    { text: 'Zobrazení logu', route: '/admin/log', visible: () => WSClient.isCurrentUserAdministrator },            
];

class MainMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenuData: false,
            showMenuStat: false,
            showMenuAdmin: false,
            treeData: [],
            treeStat: [],
            treeAdmin: []
        };
    }

    //
    componentDidMount() {

        WSClient.addUserChangeListener(this.handleUserChange);
        this.handleUserChange();
    }

    //
    componentWillUnmount() {
        WSClient.removeUserChangeListener(this.handleUserChange);
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen, nebo dojde k nějaké chybě komunikace
    handleUserChange = () => {

        // Podle přihlášeného uživatele upravíme menu
        this.setState(state => ({
            treeData: treeData.filter(w => w.visible()),
            treeStat: treeStat.filter(w => w.visible()),
            treeAdmin: treeAdmin.filter(w => w.visible()),
        }));
    }

    render() {

        return (
            <React.Fragment>
                <div id="DivLogo">
                    <Link
                        id='LinkIndex'
                        to={'/'}
                        ref={(link) => {
                            this.anchor = link;
                        }}><img src={Logo} alt="ČNNS" /></Link>
                </div>                

                {this.state.treeData.length > 0 &&
                    <button className={this.state.showMenuData ? "selected" : window.location.pathname.startsWith("/data/") ? "active" : ""}
                        title="Data"
                        onMouseEnter={() => this.setState({ showMenuData: true })}
                        onMouseLeave={() => this.setState({ showMenuData: false })}
                    >
                        <Data />
                    </button>
                }

                <PopupMenu
                    show={this.state.showMenuData}
                    onMouseEnter={() => this.setState({ showMenuData: true })}
                    onMouseLeave={() => this.setState({ showMenuData: false })}
                    tree={this.state.treeData}
                    title="Data"
                />

                {this.state.treeStat.length > 0 &&

                    <button className={this.state.showMenuStat ? "selected" : window.location.pathname.startsWith("/stat/") ? "active" : ""}
                        title="Výstupy"
                        onMouseEnter={() => this.setState({ showMenuStat: true })}
                        onMouseLeave={() => this.setState({ showMenuStat: false })}
                    >
                        <Statistic />
                    </button>
                }

                <PopupMenu
                    show={this.state.showMenuStat}
                    onMouseEnter={() => this.setState({ showMenuStat: true })}
                    onMouseLeave={() => this.setState({ showMenuStat: false })}
                    tree={this.state.treeStat}
                    title="Výstupy"
                />

                {WSClient.currentUser.clientCertificateState === erOK &&
                    <button className={window.location.pathname=== "/mail" ? "active" : ""}
                        title="Pošta"
                        onClick={() => this.props.navigate("/mail")}>
                        <Mail />
                    </button>
                }

                {this.state.treeAdmin.length > 0 &&

                    <button className={this.state.showMenuAdmin ? "selected" : window.location.pathname.startsWith("/admin/") ? "active" : ""}
                        title="Administrace"
                        onMouseEnter={() => this.setState({ showMenuAdmin: true })}
                        onMouseLeave={() => this.setState({ showMenuAdmin: false })}
                    >
                        <Administration />
                    </button>

                }
                <PopupMenu
                    show={this.state.showMenuAdmin}
                    onMouseEnter={() => this.setState({ showMenuAdmin: true })}
                    onMouseLeave={() => this.setState({ showMenuAdmin: false })}
                    tree={this.state.treeAdmin}
                    title="Administrace"
                />

                <button className={window.location.pathname=== "/documents" ? "active" : ""}
                    title="O registru"
                    onClick={() => this.props.navigate("/documents")}>
                    <AboutRDP />
                </button>
                
            </React.Fragment >
        );
    }
}

export default withRouter(withLoggedUserFormsContext(MainMenu));
